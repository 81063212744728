import Amplify from "@aws-amplify/core";
import {
  Authenticator,
  useAuthenticator,
  View,
  Image,
  useTheme,
  Heading,
  translations,
  Button,
  ButtonGroup,
  Flex,
  Link,
  Card,
  Alert,
  Grid
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEnvelope,
  faSignOut,
  faSpinner,
  faUserPlus,
  faUserLock,
  faQuestionCircle,
  faSignIn
} from "@fortawesome/pro-regular-svg-icons";
import flash from "./flaskFlash";
import readJSONConfig from "./readJSONConfig";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import ErrorBoundary from "./ErrorBoundary";

const url_for = window.url_for;
// const read_json_config = window.read_json_config

/*Amplify.configure({
	Auth: {
		region: 'us-east-1',
		userPoolId: 'us-east-xxxexamplexxx',
		userPoolWebClientId: 'zzzzexamplezzzz',
		authenticationFlowType: 'USER_PASSWORD_AUTH'
	}
});*/

const cv_client = readJSONConfig("#cv-client");
const login_config = readJSONConfig("#login-config");

I18n.putVocabularies(translations);

let tokenString = `Exception migrating user in app client ${login_config.userPoolWebClientId}`;

I18n.putVocabularies({
  en: {
    [tokenString]: "An account with this email does not exist. Please input a valid email.",
    "User does not exist": "Sorry, we couldn't find your account. Please enter the email address you registered with and make sure you are accessing the correct organization.",
    "UserMigration failed with error Multiple rows were found for one().":
        "There's a problem with your account. Please contact support",
    "Password attempts exceeded":
      "You have exceeded the maximum number of login requests.  Please try again in 15 minutes.",
    "Unrecognizable lambda output": "There was a server error while creating your account. Please try again. If the " +
        "problem persists, please contact support",
    "PreSignUp failed with error NOT_REGISTERED.": "The email entered is not registered for the event. Check the " +
        "spelling of the email address and confirm you entered the same email address you used to register.",
    "PreSignUp failed with error MULTIPLE_REGISTRATIONS.": "There is a problem with your account. Please contact " +
        "support.",
    "PreSignUp failed with error Client not found for user pool.": "There is a problem completing the registration." +
        " Please contact support."
    // "Invalid verification code provided, please try again.": "[insert error message to use here]",
  },
});

Amplify.configure({
  Auth: login_config,
});

const CopyYear = () => {
  let year = new Date().getFullYear();
  return <span className="copyright-year">{year}</span>;
};

const ClientLogo = ({width, height}) => {
  if(cv_client.logo_url) {
    return (
        <Image alt={cv_client.vendor} src={cv_client.logo_url} width={width || "60%"} height={height || "60%"}></Image>
    )
  }
}

const ImageHeader = () => {
  const { tokens } = useTheme();
  if (cv_client.logo_url) {
    return (
        <View textAlign="center" padding={tokens.space.large}>
          <ClientLogo width="60%" height="60%" />
        </View>
    );
  } else {
    return (
        <View textAlign="center" padding={tokens.space.large}>
          <h1>{cv_client.vendor}</h1>
        </View>
    );
  }
};

const openSupportLink = () => {
  window.location.href = url_for('support.contact_form');
}

const components = {
  SignIn: {
    Header() {
      return (
        <View>
          <ImageHeader />
          <Heading level={3} marginLeft="2rem">
            Please Sign In
          </Heading>
        </View>
      );
    },
    Footer() {
      const { toSignUp, toResetPassword } = useAuthenticator();
      const { tokens } = useTheme();
      return (
          <ButtonGroup
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap={tokens.space.small}
              marginTop="-1rem"
              marginBottom={tokens.space.small}
              marginLeft="2rem"
              marginRight="2rem"
              variation="default"
              fontWeight="normal"
              size="small"
            >
              <Button
                  onClick={toSignUp}
                  isFullWidth={true}
              ><FontAwesomeIcon icon={faUserPlus} />&nbsp; Create an Account</Button>
              <Button
                  onClick={toResetPassword}
                  isFullWidth={true}
              ><FontAwesomeIcon icon={faUserLock} />&nbsp; Forgot Account or Password?</Button>
              <Button
                  onClick={openSupportLink}
                  isFullWidth={true}
              ><FontAwesomeIcon icon={faQuestionCircle} />&nbsp; Support</Button>
          </ButtonGroup>
      )
    }
  },
  SignUp: {
    Header() {
      return (
          <View>
            <ImageHeader />
            <Heading level={3} marginLeft="2rem">
              Create an Account
            </Heading>
          </View>
      )
    },
    Footer() {
      const { toSignIn, toResetPassword } = useAuthenticator();
      const { tokens } = useTheme();
      return(<ButtonGroup
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          gap={tokens.space.small}
          marginTop="-1rem"
          marginBottom={tokens.space.small}
          marginLeft="2rem"
          marginRight="2rem"
          variation="default"
          fontWeight="normal"
          size="small"
      >
        <Button
            onClick={toSignIn}
            isFullWidth={true}
        ><FontAwesomeIcon icon={faSignIn} />&nbsp; Have an Account? Sign-In</Button>
        <Button
            onClick={openSupportLink}
            isFullWidth={true}
        ><FontAwesomeIcon icon={faQuestionCircle} />&nbsp; Support</Button>
      </ButtonGroup>)
    }
  },
  ResetPassword: {
    Header() {
      return (
          <View>
            <ImageHeader />
            <Heading level={3}>
              Reset Your Password
            </Heading>
          </View>
      )
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
          <Button onClick={toSignIn} isFullWidth={true} size="small" fontWeight="normal">
            <FontAwesomeIcon icon={faSignIn} />&nbsp; Back to Sign-In
          </Button>
      )
    }
  }
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Enter Your Email Here",
      isRequired: true,
      label: "Email:",
      autocomplete: "off",
    },
    password: {
      labelHidden: false,
      placeholder: "Enter Your Password Here",
      isRequired: true,
      label: "Password:",
      autocomplete: "off",
    },
  },
};

const TopNav = () => {
  return (
    <View marginBottom="2rem">
      <Flex className="bg-advocacy" justifyContent="center" padding=".5rem">
        <Flex className="cv-container" direction="row" alignItems="flex-start" gap="1rem" wrap="nowrap">
          <View>
            <Link fontSize="large" className="navbar-brand" href="/" color="#ffffff">{cv_client.vendor || "AdvocacyDay"}</Link>
          </View>
        </Flex>
      </Flex>
      <Flex className="bg-advocacy-inverse" justifyContent="center">
        <Flex className="cv-container" direction="row" alignItems="flex-start" gap="1rem" wrap="nowrap">
          <View className="nav-item">
            <Link
                fontSize="normal"
                className="navbar-link"
                href={url_for('base.index')}
            >
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>
          </View>
          <View className="nav-item">
            <Link

                className="navbar-link"
                fontSize="normal"
                href={url_for('support.contact_form')}
            >
              <FontAwesomeIcon icon={faEnvelope} /> Support
            </Link>
          </View>
        </Flex>
      </Flex>
    </View>
  )
}

const BottomNav = () => {
  return (
      <View height="3rem" className="bg-advocacy-inverse">
        <Flex className="bg-advocacy-inverse" justifyContent="center" direction="row">
          <Flex className="cv-container" direction="row" justifyContent="space-between" wrap="nowrap">
            <View className="nav-item">
              <Link fontSize="normal" className="navbar-link" href="https://constituentvoice.com/" target="_blank">
                Constituent Voice by Advocacy Associates
              </Link>
              <Link fontSize="normal" className="navbar-link" href={url_for('support.contact_form')}>
                Contact Us
              </Link>
              <Link fontSize="normal" className="navbar-link" href={url_for('base.about')}>
                About Us
              </Link>
              <Link fontSize="normal" className="navbar-link" href={url_for('base.terms_and_conditions')}>
                Terms
              </Link>
              <Link fontSize="normal" className="navbar-link" href={url_for('base.privacy_policy')}>
                Privacy
              </Link>
            </View>
            <View className="nav-item">
              <Link className="navbar-link" fontSize="normal" href="https://advocacyassociates.com/" target="_blank">
                &copy; <CopyYear /> Advocacy Associates, LLC
              </Link>
            </View>
          </Flex>
        </Flex>
      </View>
  )
}


const IEError = () => {
  const { tokens } = useTheme();
  return(
        <Card variation="outlined">
          <Flex direction="column" alignItems="flex-start" gap={tokens.space.xs}>
            <Heading level={5}>
              <h4>Unable to load application</h4>
            </Heading>

            <Alert variation="error" isDismissible={false} hasIcon={true}>
              <p>
                Unfortunately Your browser is too old to work with this
                application. Please open the link in Microsoft Edge, Google
                Chrome, Mozilla Firefox, or other updated browser.{" "}
              </p>
              <p>
                If you are unable use a different browser on this device,
                please try on another device, such as a tablet or phone.
              </p>
              <p>We apologize for the inconvenience.</p>
            </Alert>
          </Flex>
        </Card>
  )
};

const Loading = ({loadingMessage, signOut}) => {
  return (
      <Card variation="outlined">
        <Flex direction="column" alignItems="center" justifyContent="center" >
          <Heading level={5}>{loadingMessage}</Heading>
          <View padding="1rem 0">
            <FontAwesomeIcon
                icon={faSpinner}
                size="4x"
                spin
            />
          </View>
          <View padding="1rem 0">
            <Button variation="link" onClick={signOut}>
              Sign out <FontAwesomeIcon icon={faSignOut} />
            </Button>
          </View>
        </Flex>
      </Card>
  )
}

const CVAuthenticator = (props) => {
  let initialState = "signIn";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get("pw")) {
    initialState = "resetPassword";
  }

  const services = {
    async handleForgotPassword(username) {
      // grab the auth state of this user. Can't find an easy way to do this
      fetch(url_for("auth.cognito_user_fix_reset"), {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username }),
      })
          .then((resp) => {
            return resp.blob();
          })
          .then((raw) => {
            // return the forgotPassword completion regardless
            return Auth.forgotPassword(username);
          });
    },
  };
  return (
      <Authenticator
        formFields={formFields}
        initialState={initialState}
        loginMechanisms={["email"]}
        components={components}
        services={services}
        >
        {({ signOut, user }) => {
          let sess = user.getSignInUserSession();
          let auth_data = {
            access_token: sess.getAccessToken().getJwtToken(),
            id_token: sess.getIdToken().getJwtToken(),
            refresh_token: sess.getRefreshToken().getToken(),
            client_id: login_config.userPoolWebClientId,
          };

          let forceLogout = urlParams.get("logout") || "";
          let loadingMessage = "Loading ...";
          fetch(url_for("auth.cognito_auth_session"), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              auth_data: auth_data,
            }),
          }).then((resp) => {
              if (!resp.ok) {
                // throw Error('Unable to set tokens. Please contact the administrators.')
                loadingMessage = "Signing you out ...";
                try {
                  Auth.signOut();
                  window.doSignOut = true;
                } catch (e) {
                  console.log(e);
                  console.log("Cannot sign out!");
                }
              }
              else {
                return resp.json();
              }
            }).then((output) => {
                if(window.doSignOut) {
                  window.doSignOut = false;
                  // nothing else should happen
                }
                else {

                  loadingMessage = "Signing in ...";
                  let login_to = urlParams.get("login_to");
                  if (login_to) {
                    login_to = unescape(login_to);
                    // ensure we don't switch domains!
                    let test_login_to = document.createElement("a");
                    test_login_to.href = login_to;
                    let origin = window.location.origin;
                    let path = test_login_to.pathname;
                    if (!path.startsWith("/")) {
                      path = "/" + path;
                    }
                    if (path === "/login") {
                      login_to = url_for("base.index");
                    } else {
                      login_to = origin + path;
                    }
                  } else {
                    login_to = url_for("base.index");
                  }
                  window.location.href = login_to;
                }
              }).catch((err) => {
                flash(
                    err.message ||
                    "Unknown failure when setting the device. Please contact the administators.",
                    "error"
                );
              });

          return (
              <Loading loadingMessage={loadingMessage} signOut={signOut}/>
          );
        }}
      </Authenticator>
  )
};

const CVGridLayout = (props) => {
  return(
      <Grid templateRows="8rem 1fr 3rem" templateColumns="1fr 30rem 1fr" height="100vh" alignContent="center">
        <View columnSpan={3}><TopNav /></View>
        <View></View><View>{props.children}</View><View></View>
        <View columnSpan={3}><BottomNav /></View>
      </Grid>
  )
};

const MainDisplay = () => {
  return (
      <CVGridLayout>
        <ErrorBoundary>
          <CVAuthenticator />
        </ErrorBoundary>
      </CVGridLayout>
  )
};

export default function App() {
  if (window.document.documentMode) {
    // IE
    return (
      <div id="main-content">
        <CVGridLayout>
          <IEError />
        </CVGridLayout>
      </div>
    );
  } else {
    return <MainDisplay />
  }
}
