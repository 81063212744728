export const flash = (message, category, options) => {
    category = category === 'error' ? 'danger' : category;
    options = options || {};

    let clsicon = document.createElement('i');
    clsicon.setAttribute('aria-hidden', 'true');
    clsicon.classList.add('fas', 'fa-times');

    let clstext = document.createElement('span');
    clstext.classList.add('sr-only')
    clstext.append('Close');

    let clsbtn = document.createElement('button');
    clsbtn.classList.add('close')
    clsbtn.setAttribute('data-dismiss', 'alert');
    clsbtn.append(clsicon, clstext)

    let msgtext = document.createElement('p');
    msgtext.innerHTML = message;

    let msg = document.createElement('div');
    msg.classList.add('fade', 'in', 'alert', 'alert-' + category)
    msg.setAttribute('role', 'alert');
    msg.append(clsbtn, msgtext)

    let selector = options.selector || '.messages';
    let elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
        element.prepend(msg.cloneNode(true));
    })
};

export default flash;
