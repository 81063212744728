export const readJSONConfig = (selector) => {
    let element = document.querySelector(selector);
    let data = element.innerText;
    try {
        if(data) {
            return JSON.parse(data);
        }
    }
    catch {
        console.log('Invalid JSON in config');
    }
};

export default readJSONConfig;
