import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{
              width: "200px",
              height: "auto",
              paddingBottom: "15px",
            }}
            src="https://cdn0.iconfinder.com/data/icons/shift-free/32/Error-512.png"
          />
          <h2 style={{ paddingBottom: "8px" }}>
            Oops! We encountered a problem trying to load the application
          </h2>
          <h6>
            Usually this happens when your browser is out of date. Please try
            installing the latest browser updates available.
            <br />
            <br />
            It may also be caused if you are using a VPN or firewall that
            prevents the application from loading. If you're on a work network
            or VPN, please contact your IT department.
            <br />
            <br />
            For more info please visit:{" "}
            <a href="https://constituentvoice.com/documentation/">
              the documentation
            </a>
            <br />
            <br />
            To request support on this issue, contact us here:{" "}
            <a href="https://advocacyday.constituentvoice.net/contact">
              Support
            </a>
          </h6>
        </div>
      );
    }

    return this.props.children;
  }
}
